<div class="contenedor">
	<div class="flex-container">
		<div>
			<img src="equipo_cecan.jpg">
		</div>
		<div class="margen">
			<p><strong>MISIÓN</strong></p>
			<p>Cambiar la historia del cáncer en Chile.<br>&nbsp;</p>
			<p><strong>VISIÓN</strong></p>
			<p><span >Ser reconocidos como el centro más influyente en políticas públicas en cáncer.<br><br></span></p>
			<p><strong>VALORES</strong></p>
			<p><span >Compromiso social, excelencia, transparencia, justicia, colaboración, compasión.</span></p>						
		</div>
	</div>
</div>