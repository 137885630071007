import { Component } from '@angular/core';

@Component({
  selector: 'app-cancer-causes',
  standalone: true,
  imports: [],
  templateUrl: './cancer-causes.component.html',
  styleUrl: './cancer-causes.component.css'
})
export class CancerCausesComponent {

}
