import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CancerTodayComponent } from './cancer-today/cancer-today.component';
import { CancerOvertimeComponent } from './cancer-overtime/cancer-overtime.component';
import { CancerTomorrowComponent } from './cancer-tomorrow/cancer-tomorrow.component';
import { CancerCausesComponent } from './cancer-causes/cancer-causes.component';
import { CancerSurvivalComponent } from './cancer-survival/cancer-survival.component';
import { CancerMortalidadComponent } from './cancer-mortalidad/cancer-mortalidad.component';
import { LeyCancerComponent } from './ley-cancer/ley-cancer.component';
import { HomeIndexComponent } from './home-index/home-index.component';
import { HomeAcercaDeComponent } from './home-acerca-de/home-acerca-de.component';
import { HomeFichasComponent } from './home-fichas/home-fichas.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home/home-index', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      { path: 'cancer-today', component: CancerTodayComponent },
      { path: 'cancer-overtime', component: CancerOvertimeComponent },
      { path: 'cancer-tomorrow', component: CancerTomorrowComponent },
      { path: 'cancer-causes', component: CancerCausesComponent },
      { path: 'cancer-survival', component: CancerSurvivalComponent },
	  { path: 'cancer-mortalidad', component: CancerMortalidadComponent },
      { path: 'ley-cancer', component: LeyCancerComponent },
      { path: 'home-index', component: HomeIndexComponent },
	  { path: 'home-acerca-de', component: HomeAcercaDeComponent },
	  { path: 'home-fichas', component: HomeFichasComponent },
    ]
  },
  { path: '**', redirectTo: 'home' },
  

  // otras rutas aquí
];


