<div id="bdSidebar" class="d-flex flex-column flex-shrink-0 p-3 text-white offcanvas-md offcanvas-start bdSidebar">
    <a href="#"  class="navbar-brand"></a>
    <ul class=" nav flex-column mb-auto">
		<li class="nav-item mb-4">
            <a class="btn btn-survival" routerLink="/home/ley-cancer">
                <span class="btn-top">Ley Nacional de Cáncer</span>
            </a>
        </li>
        <li class="nav-item mb-4">
            <a class="btn btn-today" routerLink="/home/cancer-today">
                <span class="btn-top">Prevalencia</span>
            </a>
        </li>

        <li class="nav-item mb-4">
            <a class="btn btn-over-time" routerLink="/home/cancer-overtime">
                <span class="btn-top">Incidencia</span>
            </a>
        </li>
        <li class="nav-item mb-4">
            <a class="btn btn-tomorrow" routerLink="/home/cancer-tomorrow">
                <span class="btn-top">Proyecciones</span>
            </a>
        </li>
        <li class="nav-item mb-4">
            <a class="btn btn-causes" routerLink="/home/cancer-causes">
                <span class="btn-top">Causas</span>
            </a>
        </li>
        <li class="nav-item mb-4">
            <a class="btn btn-survival" routerLink="/home/cancer-survival">
                <span class="btn-top">Sobrevida</span>
            </a>
        </li>
		<li class="nav-item mb-4">
            <a class="btn btn-survival" routerLink="/home/cancer-mortalidad">
                <span class="btn-top">Mortalidad</span>
            </a>
        </li>
    </ul>

</div>



