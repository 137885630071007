<div class="filter-form">
  <form  class="selector-container">
    <mat-form-field appearance="fill">
		<mat-label>Cáncer</mat-label>
		<mat-select [(ngModel)]="filtro.cancer" name="cancer" (selectionChange)="cargar()">
		  <mat-option value="" disabled>Selecciona una opción</mat-option>
		  <mat-option *ngFor="let cancer of canceres" [value]="cancer.id">{{ cancer.nombre }}</mat-option>
		</mat-select>
	  </mat-form-field>

	  <mat-form-field appearance="fill">
		<mat-label>Región</mat-label>
		<mat-select [(ngModel)]="filtro.region" name="region" (selectionChange)="cargar()">
		  <mat-option value="" disabled>Selecciona una opción</mat-option>
		  <mat-option *ngFor="let region of regiones" [value]="region.id">{{ region.nombre }}</mat-option>
		</mat-select>
	  </mat-form-field>


	  <mat-form-field appearance="fill">
		<mat-label>Edad</mat-label>
		<mat-select [(ngModel)]="filtro.edad" name="edad" (selectionChange)="cargar()">
		  <mat-option value="" disabled>Selecciona una opción</mat-option>
		  <mat-option *ngFor="let edad of edades" [value]="edad.id">{{ edad.nombre }}</mat-option>
		</mat-select>
	  </mat-form-field>


	  <mat-form-field appearance="fill" [ngClass]="{'hidden': [15, 17, 16, 18, 19].includes(filtro.cancer)}">
		<mat-label>Sexo</mat-label>
		<mat-select [(ngModel)]="filtro.sexo" name="sexo" (selectionChange)="cargar()">
		  <mat-option value="" disabled>Selecciona una opción</mat-option>
		  <mat-option *ngFor="let sexo of sexos" [value]="sexo.id">{{ sexo.nombre }}</mat-option>
		</mat-select>
	  </mat-form-field>

  </form>
</div>


<div id="tasa_cruda"></div>