import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-left-side-menu',
  standalone: true,
  imports: [RouterModule], // Asegúrate de importar RouterModule aquí
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.css']
})
export class LeftSideMenuComponent {}
