import { Component, AfterViewInit } from '@angular/core';
import { EstadisticasService } from '../services/estadisticas.service';
import { Filtro } from '../models/filtro.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select'; 
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-cancer-mortalidad',
  standalone: true,
  imports: [FormsModule,CommonModule,MatFormFieldModule,MatSelectModule],
  templateUrl: './cancer-mortalidad.component.html',
  styleUrl: './cancer-mortalidad.component.css'
})
export class CancerMortalidadComponent implements AfterViewInit{
  canceres: { id: number; nombre: string }[];
  regiones: { id: number; nombre: string }[];
  edades: { id: number; nombre: string }[];
  sexos: { id: number; nombre: string }[];

  filtro: Filtro;

  graficoTasaCruda: any;

  constructor(private estadisticasServ: EstadisticasService){
	this.canceres = this.estadisticasServ.getCanceres();
	this.regiones = this.estadisticasServ.getRegiones();
	this.edades = this.estadisticasServ.getEdades();
	this.sexos = this.estadisticasServ.getSexos();
	this.filtro = new Filtro();

  }
  ngAfterViewInit() {
    this.estadisticas();
	this.cargar();
	
  }
  estadisticas() {
	try{
		this.graficoTasaCruda = Highcharts.chart('tasa_cruda', {
		  chart: {
			type: 'line',
		  },
		  title: {
			text: 'Tasa cruda',
		  },
		  xAxis: {
			categories: this.estadisticasServ.getCategorias(),
		  },
		  yAxis: {
			title: {
			  text: '',
			},
		  },
		  series: [{
				type: "line",
				name: "Tasa cruda",
				data: []
			}
		  ],
		});
	}catch(e){}

  }
  cargar(){
	if(this.graficoTasaCruda){
		this.estadisticasServ.getEstadisticas(this.filtro).subscribe({
			next: data => {
				let datos = data.data.map((d:any) => {
					return d.tasa_cruda
				});
				let categorias = data.data.map((d:any) => {
					return d.anio
				});
				this.graficoTasaCruda.xAxis[0].setCategories(categorias);
				this.graficoTasaCruda.series[0].setData(datos, true);
			}
		});
	}
  }
}