<div class="content-fluid">
    <header>
        <app-top-navbar></app-top-navbar>
    </header>
    <main>
        <div class="p-0 d-flex h-100 ">
            <app-left-side-menu></app-left-side-menu>

            <div class="flex-fill">
                <div class="p-2 d-md-none d-flex text-white bg-danger justify-content-center">
                    <a href="#" class="text-white" 
                        data-bs-toggle="offcanvas"
                        data-bs-target="#bdSidebar">
                        <i class="fa-solid fa-bars"></i>
                    </a>
                    <span class="ms-3">Menu</span>
                </div>
                <div class="p-4">            
                    <div class="row">
                        <div class="col">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            
            </div>

        </div>
    </main>
  </div>
  