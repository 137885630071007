import { Component } from '@angular/core';
import { NgbAlertModule, NgbCarouselModule  } from '@ng-bootstrap/ng-bootstrap';
import { LeftSideMenuComponent } from '../shared/left-side-menu/left-side-menu.component';
import { TopNavbarComponent } from '../shared/top-navbar/top-navbar.component';
import { RouterModule } from '@angular/router';




@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    NgbAlertModule,
    NgbCarouselModule,
    LeftSideMenuComponent,
    TopNavbarComponent,
    RouterModule
    
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {


}
