<div class="row pb-2 align-items-center">
  <div class="col-12 col-md-2 cancer-tomorrow-section-title">
      <h2>C&aacute;ncer Proyecciones</h2>
  </div>

  <div class="col-12 col-md-10">
      <hr class="cancer-tomorrow-section-title-separator">
  </div>
</div>


<div class="cancer-tomorrow-section-container">
  
  <div class="row">
      <div class="col-12">
          <div class="tableau-container">
            <div class='tableauPlaceholder' style='position: relative'>
					<div id="tablo" class="tableauViz"></div>
			</div>  
		  </div>
      </div>

  </div>

</div>

