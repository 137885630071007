import { Component, AfterViewInit, ElementRef } from '@angular/core';
declare var tableau: any;

@Component({
  selector: 'app-cancer-overtime',
  standalone: true,
  imports: [],
  templateUrl: './cancer-overtime.component.html',
  styleUrl: './cancer-overtime.component.css'
})
export class CancerOvertimeComponent implements AfterViewInit{
	private viz: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.initTableau();
  }

  initTableau() {
    const containerDiv = this.el.nativeElement.querySelector('#tablo');
    const url = "https://public.tableau.com/views/Incidenciaymortalidadcncer2022/Dashboard1?:language=es-ES&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link";

    const options = {
      hideTabs: false,
	  toolbar:true,
      width: "100%",
      height: containerDiv.offsetWidth > 800 ? "827px" : "1077px",
      onFirstInteractive: () => {
        console.log("Tableau visualization loaded");
      }
    };

    this.viz = new tableau.Viz(containerDiv, url, options);
  }
	ngOnDestroy(): void {
		if (this.viz) {
		  this.viz.dispose();
		}
	  }

}
