<div class="row pb-2 align-items-center">
  <div class="col-12 col-md-2 ley-cancer-section-title">
      <h2>Ley de Cáncer</h2>
  </div>

  <div class="col-12 col-md-10">
      <hr class="ley-cancer-section-title-separator">
  </div>
</div>

<div class="ley-cancer-section-container">

	<div id="overlay_cancer">
		<div class="imagen-leyes" (click)="hideTooltip()" ></div>

	  <div class="area ley2"
		   (click)="showTooltip($event,2)" 
		   ></div>
	  <div class="area ley3"
		   (click)="showTooltip($event,3)" 
		   ></div>
	  <div class="area ley4"
		   (click)="showTooltip($event,4)" 
		   ></div>
	  <div class="area ley5"
		   (click)="showTooltip($event,5)" 
		   ></div>

	  <div class="area ley1" 
		   (click)="showTooltip($event,1)" 
		    ></div>
	  <div class="area ley6"
		   (click)="showTooltip($event,6)" 
		   ></div>
	  <div class="area ley7"
		   (click)="showTooltip($event,7)" 
		   ></div>
	  <div class="area ley8"
		   (click)="showTooltip($event,8)" 
		   ></div>
	  <div class="area ley9"
		   (click)="showTooltip($event,9)" 
		   ></div>
  </div>
  <div class="tooltip_cancer" id="tooltip_cancer" (click)="hideTooltip()"></div>

</div>