import { Component } from '@angular/core';

@Component({
  selector: 'app-home-acerca-de',
  standalone: true,
  imports: [],
  templateUrl: './home-acerca-de.component.html',
  styleUrl: './home-acerca-de.component.css'
})
export class HomeAcercaDeComponent {

}
