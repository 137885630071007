<div class="row pb-2 align-items-center">
  <div class="col-12 col-md-2 cancer-causes-section-title">
      <h2>C&aacute;ncer Causas</h2>
  </div>

  <div class="col-12 col-md-10">
      <hr class="cancer-causes-section-title-separator">
  </div>
</div>


<div class="cancer-causes-section-container">
  
  <div class="row">
      <div class="col-12">
          <div class="powerbi-container">
            
          </div>
      </div>

  </div>

</div>
