import { Component } from '@angular/core';

@Component({
  selector: 'app-home-index',
  standalone: true,
  imports: [],
  templateUrl: './home-index.component.html',
  styleUrl: './home-index.component.css'
})
export class HomeIndexComponent {

}
