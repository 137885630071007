import { Component,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ley-cancer',
  standalone: true,
  imports: [],
  templateUrl: './ley-cancer.component.html',
  styleUrl: './ley-cancer.component.css',
  encapsulation: ViewEncapsulation.None
})
export class LeyCancerComponent {
	idAnterior: number = 0;
	constructor(){}
	

	showTooltip(e: MouseEvent, id: number) {

		const tooltip = document.getElementById('tooltip_cancer');
		if(tooltip){
			if(this.idAnterior !== id){
				while (tooltip.firstChild) {
					tooltip.removeChild(tooltip.firstChild);
				}
			}
		}
		let inf = this.info(id);

		if(tooltip){
			
			if(this.idAnterior !== id){
				tooltip.appendChild(this.crearTabla(inf.cabecera,inf.datos));
				this.idAnterior = id;
			}
			if(tooltip.style.opacity != '1'){
				tooltip.style.opacity = '1';
			}
			if(tooltip.style.opacity != 'visible'){
				tooltip.style.visibility = 'visible';
			}
		}

	}

	hideTooltip() {
		const tooltip = document.getElementById('tooltip_cancer');
		if(tooltip){
			tooltip.style.visibility = 'hidden';
			tooltip.style.opacity = '0';
		}

	}
	crearTabla(cabecera:Array<string>,datos:Array<any>){
		const table = document.createElement('table');
		table.id = 'info_ley';

		// Crear el encabezado
		const thead = document.createElement('thead');
		const headerRow = document.createElement('tr');

		for(let col of cabecera){
			let th1 = document.createElement('th');
			th1.textContent = col;
			headerRow.appendChild(th1);
		}

		thead.appendChild(headerRow);
		table.appendChild(thead);

		// Crear el cuerpo de la tabla
		const tbody = document.createElement('tbody');
		for(let fila of datos){
			let row1 = document.createElement('tr');
			for(let celda of fila){
				let cell1_1 = document.createElement('td');
				cell1_1.textContent = celda;
				row1.appendChild(cell1_1);
			}
			tbody.appendChild(row1);
		}

		// Añadir el cuerpo a la tabla
		table.appendChild(tbody);
		return table;
	}
	info(id:number){
		switch(id){
			case 1:
				return this.c1();
			case 2:
				return this.c2();
			case 3:
				return this.c3();
			case 4:
				return this.c4();
			case 5:
				return this.c5();
			case 6:
				return this.c6();
			case 7:
				return this.c7();
			case 8:
				return this.c8();
			case 9:
				return this.c9();
			default:
				return this.c1();
		}
	}
	c1(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Resolución Grupos de Trabajo encargados de presentar propuesta del Plan Nacional del Cáncer 2022-2027","Cumplido"],
			["Declaración de Conflicto de Intereses de grupo de trabajo","Cumplido"],
			["Desarrollo Informe de avance de Plan Nacional de Cáncer 2018-2028","En trámite final"],
			["Desarrollo contenido propuesta de Plan de Cáncer 2022-2027","En desarrollo"]
		];
		return {cabecera,datos};
	}
	c2(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Reglamento dictado a través del Ministerio de Salud, fijará los requisitos y procedimientos a que deberán sujetarse los concursos públicos","En trámite final"]
		];
		return {cabecera,datos};
	}
	c3(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Diagnóstico de brechas: necesario para construir el diagnóstico de brechas de RRHH y su planteamiento en el Plan de acción","Cumplido parcialmente"],
			["Plan de formación: el Ministerio de Salud definirá, planes de formación orientados a fortalecer y acelerar la formación de médicos cirujanos especialistas y cirujanos dentistas especialistas, así como de profesionales y técnicos de la salud prioritarios para la Red Oncológica","No iniciado"],
			["Capacitación de RRHH en temática cáncer: el Ministerio de Salud fomentará la formación, capacitación, educación continua y especialización del recurso humano en temáticas de cáncer","Cumplido parcialmente"]


		];
		return {cabecera,datos};
	}
	c4(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Informe de Factibilidad: documento que expone recomendaciones relativas al uso de tecnología sanitaria y medicamentos viables de evaluar","Cumplido"],
			["Resolución que aprobará grupo elaborador de GPC: cada año la Subsecretaría deberá aprobar resolución del grupo de trabajo encargado de elaborar GPC","En desarrollo"],
			["Incorporación de  ISP a GPC: la Subsecretaría de Salud Pública oficiará acerca de los resultados de los fármacos o dispositivos médicos.","Cumplida designación / Pendiente informe"],
			["Incorporación de RRAA a GPC: la Subsecretaría de Redes Asistenciales deberá proporcionar a la Subsecretaría de Salud Pública información acerca de la capacidad de implementación de las intervenciones sobre las cuales se realizarán recomendaciones","Cumplida designación / Pendiente la solicitud formal de información "],
			["Informes de RRAA: los informes derivados de este proceso deberán ser realizados en un formato claro y conciso que permita al grupo elaborador realizar los juicios necesarios para realizar la recomendación.","Pendiente"],
			["Licitación para proceso de GPC: este proceso será llevado a cabo por la Subsecretaría de Salud Pública.","En desarrollo"],
			["Resolución de las guías: el Ministro de Salud aprobará mediante resolución las guías de práctica clínica para el tratamiento de los distintos tipos de cánceres y patologías asociadas","En desarrollo"],
			["Resolución del panel: cada año la Subsecretaría de Salud Pública aprobará mediante resolución el o los grupos de trabajos encargados de presentar una propuesta de las guías de práctica clínica a elaborar o actualizar","En desarrollo"]

		];
		return {cabecera,datos};
	}
	c5(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Se deberá otorgar consejería genética a los pacientes diagnosticados de cáncer en caso de detectarse factores de riesgo, así como a sus familiares","Pendiente"]

		];
		return {cabecera,datos};
	}
	c6(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Resolución que da inicio al proceso de elección y convocación a participar; Comunicación de las candidaturas; Votación  ","Cumplido "],
			["Listado de Asociaciones Científicas: el Ministerio de Salud mantendrá en su sitio electrónico el listado de asociaciones vinculadas al cáncer","Cumplido "],
			["Listado de Fundaciones u Organizaciones de Pacientes: el Ministerio de Salud mantendrá en su sitio electrónico un listado de Fundaciones u Organizaciones de pacientes vinculadas al cáncer","Cumplido "],
			["Reglamento de CNC: eglamento que establecerá el procedimiento y la forma de funcionamiento de la Comisión","En desarrollo"],
			["Funcionamiento de la Comisión Nacional del Cáncer; Publicación Actas de la Comisión Nacional del Cáncer; Elección del Presidente de la Comisión","Cumplido "],
			["Funciones de la CNC: asesorar en la elaboración de planes y programas; Realizar recomendaciones en la revisión de guías de práctica clínica, normas y protocolos. Evaluar las intervenciones oncológicas de alto costo y alta efectividad para la población","En desarrollo"]

		];
		return {cabecera,datos};
	}
	c7(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
		["Normas técnicas (derivación y contra derivación, establecimientos para ser incorporados a la red, perfil y competencias del director técnico)","En desarrollo inicial"],
		["Reglamento de la categorización y estándares de los centros: expedido por el Ministerio de Salud que determine la categorización de establecimiento de salud, estándares mínimos que deben cumplir dichos centros y zonas geográficas cubiertas","Pendiente"],
		["Reglamento de la categorización y estándares de los centros: propondrá la normalización de los centros oncológicos existentes, la creación de centros a lo largo de la Red Asistencial y el perfeccionamiento de la infraestructura necesaria para el diagnóstico y tratamiento oncológico","Cumplido parcialmente"],
		["Resolución establecimientos que componen cada zona y su complejidad: el detalle específico de los establecimientos que componen cada zona y su nivel de complejidad será definido en una resolución del Ministro de Salud","En desarrollo"]
		];
		return {cabecera,datos};
	}
	c8(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Reglamento: determinará las características del registro y los datos que deberán ser informados por los prestadores de salud","Cumplido "],
			["Norma de funcionamiento del registro, manual de usuario y manual de derivación: manual que especifica la plataforma para registrar los nuevos casos de cáncer","En Desarrollo"],
			["Norma de funcionamiento del registro, manual de usuario y derivación: el Ministro de Salud tendrá seis meses desde la entrada en vigencia el reglamento de la LNC para habilitar la plataforma de soporte del Registro Nacional del Cáncer.","Pendiente"],
			["Formulario de notificación: la notificación de la enfermedad por cáncer se realizará por los formularios que el Ministerio de Salud emita para estos efectos","Pendiente"],
			["Notificación Obligatoria: será obligación de todo médico cirujano y cirujano dentista que procese o analice muestras, notificar los casos de cáncer, en la forma dispuesta a través del Registro Nacional de Cáncer","Pendiente"],
			["Delegado: todo establecimiento de salud, o que realice análisis de muestras de laboratorio, vinculadas al diagnóstico de cáncer, deberá designar a un profesional como delegado para efectos de la notificación","En desarrollo"]



		];
		return {cabecera,datos};
	}
	c9(){
		let cabecera = ["Descripción","Estado de Avance"];
		let datos = [
			["Priorizar líneas de investigación definidas por el Plan y Comisión Nacional del Cáncer","No iniciado"]

		];
		return {cabecera,datos};
	}
}
