import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filtro } from '../models/filtro.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EstadisticasService {
  private apiUrl = environment.apiUrl + "/estadisticas";

  constructor(private http: HttpClient) {}


  getEstadisticas(filtro: Filtro): Observable<any> {
    const params = new HttpParams()
	.set('cancer', filtro.cancer)
	.set('region', filtro.region)
	.set('edad', filtro.edad)
	.set('sexo', filtro.sexo);
    
    return this.http.get<any>(this.apiUrl, { params });
  }
  getCanceres(){
	return [
		{ id: 0, nombre: "Todos los Tumores (Neoplasias)" },
		{ id: 1, nombre: "Tumores malignos del Labio, de la cavidad bucal y de la faringe" },
		{ id: 2, nombre: "Tumor maligno del Esófago" },
		{ id: 3, nombre: "Tumor maligno del Estómago" },
		{ id: 4, nombre: "Tumor maligno del Colon" },
		{ id: 5, nombre: "Tumor maligno del Recto" },
		{ id: 6, nombre: "Tumor maligno del Ano y del conducto anal" },
		{ id: 7, nombre: "Tumor maligno del Hígado y de las vías biliares intrahepáticas" },
		{ id: 8, nombre: "Tumor maligno de la Vesícula biliar, de otras partes y las no especificadas de las vías biliares" },
		{ id: 9, nombre: "Tumor maligno del Páncreas" },
		{ id: 10, nombre: "Tumor maligno de la Laringe" },
		{ id: 11, nombre: "Tumor maligno de los Bronquios y del pulmón" },
		{ id: 12, nombre: "Tumores malignos de los huesos y de los cartílagos articulares de los miembros (Osteosarcoma)" },
		{ id: 13, nombre: "Melanoma maligno de la piel" },
		{ id: 14, nombre: "Tumor maligno de la Mama" },
		{ id: 15, nombre: "Tumor maligno del Cuello del útero (CaCu)" },
		{ id: 16, nombre: "Tumor maligno del Cuerpo del útero" },
		{ id: 17, nombre: "Tumor maligno del Ovario" },
		{ id: 18, nombre: "Tumor maligno de la Próstata" },
		{ id: 19, nombre: "Tumor maligno del Testículo" },
		{ id: 20, nombre: "Tumor maligno del Riñón, excepto de la pelvis renal" },
		{ id: 21, nombre: "Tumor maligno de la Vejiga urinaria" },
		{ id: 22, nombre: "Encéfalo y Sistema nervioso (meninges, cerebro y SNC)" },
		{ id: 23, nombre: "Tumor maligno de la glándula Tiroides" },
		{ id: 24, nombre: "Linfoma de Hodgkin" },
		{ id: 25, nombre: "Linfoma no Hodgkin" },
		{ id: 26, nombre: "Mieloma múltiple y tumores de células plasmáticas" },
		{ id: 27, nombre: "Leucemia" }
	];
  }
  getRegiones(){
	return [
		{ id: 0, nombre: "Todo el país" },
		{ id: 15, nombre: "Región de Arica y Parinacota" },
		{ id: 1, nombre: "Región de Tarapacá" },
		{ id: 2, nombre: "Región de Antofagasta" },
		{ id: 3, nombre: "Región de Atacama" },
		{ id: 4, nombre: "Región de Coquimbo" },
		{ id: 5, nombre: "Región de Valparaíso" },
		{ id: 13, nombre: "Región Metropolitana de Santiago" },
		{ id: 6, nombre: "Región del Libertador General Bernardo O'Higgins" },
		{ id: 7, nombre: "Región del Maule" },
		{ id: 16, nombre: "Región de Ñuble" },
		{ id: 8, nombre: "Región del Biobío" },
		{ id: 9, nombre: "Región de La Araucanía" },
		{ id: 15, nombre: "Región de Los Ríos" },
		{ id: 10, nombre: "Región de Los Lagos" },
		{ id: 11, nombre: "Región de Aysén del General Carlos Ibáñez del Campo" },
		{ id: 12, nombre: "Región de Magallanes y de la Antártica Chilena" }
	];
  }
  getEdades(){
	return [
		{ id: 0, nombre: "Todas las edades" },
		{ id: 1, nombre: "65 y más" },
		{ id: 2, nombre: "60-64" },
		{ id: 3, nombre: "55-59" },
		{ id: 4, nombre: "50-54" },
		{ id: 5, nombre: "45-49" },
		{ id: 6, nombre: "40-44" },
		{ id: 7, nombre: "35-39" },
		{ id: 8, nombre: "30-34" },
		{ id: 9, nombre: "25-29" },
		{ id: 10, nombre: "20-24" },
		{ id: 11, nombre: "15-19" },
		{ id: 12, nombre: "10-14" },
		{ id: 13, nombre: "5-9" },
		{ id: 14, nombre: "0-4" }
	];
  }
  getSexos(){
	return [
		{ id: 0, nombre: "Ambos sexos" },
		{ id: 1, nombre: "Hombres" },
		{ id: 2, nombre: "Mujeres" }
	];
  }
  getCategorias(){
	let categorias = [];
	for(let i = 2002; i <= 2021; i++){
		categorias.push(`${i}`);
	}
	return categorias;
  }
}