import { Component } from '@angular/core';

@Component({
  selector: 'app-home-fichas',
  standalone: true,
  imports: [],
  templateUrl: './home-fichas.component.html',
  styleUrl: './home-fichas.component.css'
})
export class HomeFichasComponent {

}
