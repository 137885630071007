export class Filtro {
  cancer: number;
  region: number;
  edad: number;
  sexo: number;
  constructor(){
	this.cancer = 0;
	this.region = 0;
	this.edad = 0;
	this.sexo = 0;
  }
}